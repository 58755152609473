import React, { useRef } from 'react'
import { useIsomorphicEffect } from '../hooks/useIsomorphicEffect'

interface Props {
  children: JSX.Element
  animation: 'bounceIn' | 'pulse' | 'fadeIn' | 'fadeInFromRight' | 'rotateIn'
}

const addAnimation: { [key: string]: (ref: any, onScreen: boolean) => void } = {
  fadeIn: (ref, onScreen) => {
    ref.current.style.opacity = onScreen ? 1 : 0
    ref.current.style.transition = 'opacity 1s'
  },
  fadeInFromRight: (ref, onScreen) => {
    ref.current.style.opacity = onScreen ? 1 : 0
    ref.current.style.transform = onScreen ? 'translateX(0)' : 'translateX(20%)'
    ref.current.style.transition = 'opacity 1s, transform 1s'
  },
  bounceIn: (ref, onScreen) => {
    // ref.current.style.opacity = onScreen ? 1 : 0
    // ref.current.style.transition = 'opacity 1s'
    ref.current.animate(
      [
        { transform: 'scale3d(0.85, 0.85, 0.85)' },
        { transform: 'scale3d(1.06, 1.06, 1.06)' },
        { transform: 'scale3d(0.93, 0.93, 0.93)' },
        { transform: 'scale3d(1.03, 1.03, 1.03)' },
        { transform: 'scale3d(0.97, 0.97, 0.97)' },
        { transform: 'scale3d(1, 1, 1)' },
      ],
      {
        duration: 1000,
        easing: 'cubic-bezier(0.215, 0.610, 0.355, 1)',
      }
    )
  },
  pulse: (ref, onScreen) => {
    if (onScreen)
      ref.current.animate(
        [{ transform: 'scale3d(1,1,1)' }, { transform: 'scale3d(1.03, 1.03, 1.03)' }, { transform: 'scale3d(1,1,1)' }],
        {
          duration: 700,
        }
      )
  },
  rotateIn: (ref, onScreen) => {
    ref.current.style.transformOrigin = 'center'
    ref.current.style.opacity = onScreen ? 1 : 0
    ref.current.style.transform = onScreen ? 'none' : 'rotate3d(0, 1, 0, -200deg)'
    ref.current.style.transition = 'opacity 1s, transform 1s'
  },
}

export const OnScrollAnimation = ({ children, animation }: Props) => {
  let observer: IntersectionObserver
  const ref = useRef<HTMLElement>(null)

  const isomorphicEffect = useIsomorphicEffect()

  isomorphicEffect(() => {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          addAnimation[animation](ref, entry.isIntersecting)
        })
      },
      {
        threshold: 0.5,
      }
    )

    if (ref && ref.current) observer.observe(ref.current)

    return () => {
      if (ref && ref.current) observer.unobserve(ref.current)
    }
  }, [])

  const ChildComponent = React.forwardRef((props, ref) => ({ ...children, ref }))

  return <ChildComponent ref={ref} />
}
