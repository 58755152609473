import React, { useRef } from 'react'
import styled from 'styled-components'
import { OnScrollAnimation } from './onScrollAnimation'
import { Theme } from '../types/theme'

interface Props {
  name: string
  image: JSX.Element
}

export const SkillCard = ({ name, image }: Props) => {
  return (
    <OnScrollAnimation animation={'fadeIn'}>
      <_SkillCard>
        <div>{image}</div>
        <p>{name === 'react' ? 'React' : name}</p>
      </_SkillCard>
    </OnScrollAnimation>
  )
}

const _SkillCard = styled.div<{ theme: Theme }>`
  ${({ theme }) => theme.typ.techStackSkillName};
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    height: 50px;
  }

  p {
    padding-top: 10px;
    text-align: center;
  }
`
