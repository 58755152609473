import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { SectionFrame } from '../sectionFrame'
import { DesignIcon } from '../../images/designIcon'
import { FrontendIcon } from '../../images/frontendIcon'
import { BackendIcon } from '../../images/backendIcon'
import { SkillCard } from '../skillCard'
import { OnScrollAnimation } from '../onScrollAnimation'

import { Theme } from '../../types/theme'
import { useTranslation } from 'react-i18next'

export const TechStackSection = () => {
  const { t } = useTranslation()

  const skillIcons: { [key: string]: JSX.Element } = {
    // HTML5: <StaticImage src={'../../images/skillIcons/html5.png'} alt={'logo'} width={50} quality={100} />,
    // CSS3: <StaticImage src={'../../images/skillIcons/css3.png'} alt={'logo'} width={50} quality={100} />,
    // Github: <StaticImage src={'../../images/skillIcons/github.png'} alt={'logo'} width={50} quality={100} />,
    Javascript: (
      <StaticImage
        src={'../../images/skillIcons/js.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Typescript: (
      <StaticImage
        src={'../../images/skillIcons/typescript.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    'Node.js': (
      <StaticImage
        src={'../../images/skillIcons/nodejs.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Python: (
      <StaticImage
        src={'../../images/skillIcons/python.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    // prettier-ignore
    react: <StaticImage src={'../../images/skillIcons/react.png'} alt={'logo'} width={50} quality={100} placeholder={'blurred'} />,
    Gatsby: (
      <StaticImage
        src={'../../images/skillIcons/gatsby.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    PWA: (
      <StaticImage
        src={'../../images/skillIcons/pwa.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
        objectFit={'contain'}
      />
    ),
    'Styled Components': (
      <StaticImage
        src={'../../images/skillIcons/styledComponents.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Cypress: (
      <StaticImage
        src={'../../images/skillIcons/cypress.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Apollo: (
      <StaticImage
        src={'../../images/skillIcons/apollo.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Graphql: (
      <StaticImage
        src={'../../images/skillIcons/graphql.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Docker: (
      <StaticImage
        src={'../../images/skillIcons/docker.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Hasura: (
      <StaticImage
        src={'../../images/skillIcons/hasura.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Netlify: (
      <StaticImage
        src={'../../images/skillIcons/netlify.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    'Netlify CMS': (
      <StaticImage
        src={'../../images/skillIcons/netlifycms.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Auth0: (
      <StaticImage
        src={'../../images/skillIcons/auth0.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Heroku: (
      <StaticImage
        src={'../../images/skillIcons/heroku.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    Firebase: (
      <StaticImage
        src={'../../images/skillIcons/firebase.png'}
        alt={'logo'}
        width={50}
        quality={100}
        placeholder={'blurred'}
      />
    ),
    // Stripe: (
    //   <StaticImage
    //     src={'../../images/skillIcons/stripe.png'}
    //     alt={'logo'}
    //     width={50}
    //     quality={100}
    //     placeholder={'blurred'}
    //   />
    // ),
    // Wordpress: <StaticImage src={'../../images/skillIcons/wordpress.png'} alt={'logo'} width={50} quality={100} />,
    // Figma: (
    //   <StaticImage
    //     src={'../../images/skillIcons/figma.png'}
    //     alt={'logo'}
    //     width={50}
    //     quality={100}
    //     placeholder={'blurred'}
    //   />
    // ),
    // Photoshop: <StaticImage src={'../../images/skillIcons/photoshop.png'} alt={'logo'} width={50} quality={100} />,
    // Illustrator: <StaticImage src={'../../images/skillIcons/illustrator.png'} alt={'logo'} width={50} quality={100} />,
    // XD: <StaticImage src={'../../images/skillIcons/xd.png'} alt={'logo'} width={50} quality={100} />,

    //  [cypress, pwa]
  }

  return (
    <SectionFrame black={true}>
      <Wrapper>
        <OnScrollAnimation animation={'fadeIn'}>
          <H2>{t('techStack.title')}</H2>
        </OnScrollAnimation>
        <MainMethods>
          <OnScrollAnimation animation={'pulse'}>
            <MethodCard>
              <IconHolder>
                <DesignIcon height={'141px'} />
              </IconHolder>
              <p>DESIGN</p>
              <div>{t('techStack.method1')}</div>
            </MethodCard>
          </OnScrollAnimation>
          <OnScrollAnimation animation={'pulse'}>
            <MethodCard>
              <IconHolder>
                <FrontendIcon height={'160px'} />
              </IconHolder>
              <p>FRONTEND</p>
              <div>{t('techStack.method2')}</div>
            </MethodCard>
          </OnScrollAnimation>
          <OnScrollAnimation animation={'pulse'}>
            <MethodCard>
              <IconHolder>
                <BackendIcon height={'125px'} />
              </IconHolder>
              <p>BACKEND</p>
              <div>{t('techStack.method3')}</div>
            </MethodCard>
          </OnScrollAnimation>
        </MainMethods>

        <OnScrollAnimation animation={'fadeIn'}>
          <Description>{t('techStack.desc')}</Description>
        </OnScrollAnimation>

        <Skills>
          {Object.entries(skillIcons).map(([name, image]) => (
            <SkillCard key={name} name={name} image={image} />
          ))}
        </Skills>
      </Wrapper>
    </SectionFrame>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const H2 = styled.h2<{ theme: Theme }>`
  ${({ theme }) => theme.typ.techStackTitle};
  font-family: ${({ theme }) => theme.font.family.serif} !important;
  padding: 100px 0;
  text-align: center;
  letter-spacing: 0.4rem;
`

const MainMethods = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  width: 100%;
  max-width: ${({ theme }) => theme.content.width};
  margin: 0 auto;
`

const MethodCard = styled.div<{ theme: Theme }>`
  width: 260px;
  padding: 0 30px;
  margin-bottom: 30px;
  text-align: center;

  p {
    ${({ theme }) => theme.typ.techStackMethodTitle};
    letter-spacing: 0.3rem;
    padding: 22px 0 5px 0;
  }

  div {
    ${({ theme }) => theme.typ.techStackMethodDesc};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 170px;
    padding: 0 7px;
  }
`

const IconHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 150px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 100px;
  }
`

const Description = styled.div<{ theme: Theme }>`
  ${({ theme }) => theme.typ.techStackDesc};
  max-width: 840px;
  margin: 0 auto;
  padding: 90px 0 120px 0;
  text-align: center;
  letter-spacing: 0.05rem;
  line-height: 2.3rem;
`

const Skills = styled.div<{ theme: Theme }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 50px;

  max-width: ${({ theme }) => theme.content.width};

  padding-bottom: 120px;

  @media screen and (min-width: 1180px) {
    margin: 0 auto;
  }
`

// const SkillCard = styled.div<{ theme: Theme }>`
//   ${({ theme }) => theme.typ.techStackSkillName};
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//
//   div {
//     display: flex;
//     align-items: center;
//     height: 50px;
//   }
//
//   p {
//     padding-top: 10px;
//     text-align: center;
//   }
// `
