import React from 'react'

export const PaperPlaneIcon = (props: any) => (
  <svg
    {...props}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    xmlSpace="preserve"
  >
    <g>
      <path d="M36.9,506.2c-37.1,19.5-35.6,48,3.3,63.4l115.3,45.6c38.9,15.4,97.9,7,130.9-18.8L787,201.6c32.9-25.9,36.4-22.1,7.8,8.5L399.1,633.7c-28.7,30.5-20.2,68,18.8,83.1l13.5,5.3c39.1,15.1,102.8,40.5,141.6,56.3l127.9,52.1c38.8,15.7,70.6,28.8,70.7,29.1c0,0.2,0.2,1,0.4,1c0.2,0.1,9.4-33,20.3-73.4L987.1,70.9c11-40.4-10.3-57.6-47.4-38.1L36.9,506.2z" />
      <path d="M353.3,975c-2.3,6.8,78.8-117.5,78.8-117.5c23-35,10-76.8-28.8-92.6l-88.5-36.3c-38.7-15.9-57.3,2.7-41.3,41.5C273.5,770.2,355.6,968,353.3,975z" />
      <path d="M36.9,506.2c-37.1,19.5-35.6,48,3.3,63.4l115.3,45.6c38.9,15.4,97.9,7,130.9-18.8L787,201.6c32.9-25.9,36.4-22.1,7.8,8.5L399.1,633.7c-28.7,30.5-20.2,68,18.8,83.1l13.5,5.3c39.1,15.1,102.8,40.5,141.6,56.3l127.9,52.1c38.8,15.7,70.6,28.8,70.7,29.1c0,0.2,0.2,1,0.4,1c0.2,0.1,9.4-33,20.3-73.4L987.1,70.9c11-40.4-10.3-57.6-47.4-38.1L36.9,506.2z" />
      <path d="M353.3,975c-2.3,6.8,78.8-117.5,78.8-117.5c23-35,10-76.8-28.8-92.6l-88.5-36.3c-38.7-15.9-57.3,2.7-41.3,41.5C273.5,770.2,355.6,968,353.3,975z" />
    </g>
  </svg>
)
