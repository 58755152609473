import React from 'react'

export const BackendIcon = (props: any) => (
  <svg {...props} viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M113.637 0H11.3637C5.09633 0 0 5.09633 0 11.3637V28.4092C0 34.6763 5.09633 39.7729 11.3637 39.7729H113.637C119.904 39.7729 125 34.6766 125 28.4092V11.3637C125 5.09633 119.904 0 113.637 0ZM22.7272 25.5682C22.7272 27.1385 21.4565 28.4092 19.8862 28.4092C18.3159 28.4092 17.0455 27.1385 17.0455 25.5682V14.2045C17.0455 12.6342 18.3162 11.3635 19.8865 11.3635C21.4568 11.3635 22.7275 12.6342 22.7275 14.2045V25.5682H22.7272ZM34.0909 25.5682C34.0909 27.1385 32.8202 28.4092 31.2499 28.4092C29.6796 28.4092 28.4089 27.1385 28.4089 25.5682V14.2045C28.4089 12.6342 29.6796 11.3635 31.2499 11.3635C32.8202 11.3635 34.0909 12.6342 34.0909 14.2045V25.5682ZM45.4547 25.5682C45.4547 27.1385 44.184 28.4092 42.6137 28.4092C41.0434 28.4092 39.7727 27.1385 39.7727 25.5682V14.2045C39.7727 12.6342 41.0434 11.3635 42.6137 11.3635C44.184 11.3635 45.4547 12.6342 45.4547 14.2045V25.5682ZM85.2273 25.5682C82.0952 25.5682 79.5456 23.0186 79.5456 19.8865C79.5456 16.7544 82.0952 14.2045 85.2273 14.2045C88.3594 14.2045 90.9091 16.7541 90.9091 19.8862C90.9091 23.0183 88.3594 25.5682 85.2273 25.5682ZM102.273 25.5682C99.1407 25.5682 96.5911 23.0186 96.5911 19.8865C96.5911 16.7544 99.1407 14.2045 102.273 14.2045C105.405 14.2045 107.955 16.7541 107.955 19.8862C107.955 23.0183 105.405 25.5682 102.273 25.5682Z"
        fill="#F3F3F3"
      />
      <path
        d="M113.637 42.6136H11.3637C5.09633 42.6136 0 47.71 0 53.9774V71.0228C0 77.29 5.09633 82.3866 11.3637 82.3866H113.637C119.904 82.3866 125 77.2903 125 71.0228V53.9774C125 47.71 119.904 42.6136 113.637 42.6136ZM22.7272 68.1818C22.7272 69.7522 21.4565 71.0228 19.8862 71.0228C18.3159 71.0228 17.0455 69.7522 17.0455 68.1818V56.8181C17.0455 55.2478 18.3162 53.9771 19.8865 53.9771C21.4568 53.9771 22.7275 55.2478 22.7275 56.8181V68.1818H22.7272ZM34.0909 68.1818C34.0909 69.7522 32.8202 71.0228 31.2499 71.0228C29.6796 71.0228 28.4089 69.7522 28.4089 68.1818V56.8181C28.4089 55.2478 29.6796 53.9771 31.2499 53.9771C32.8202 53.9771 34.0909 55.2478 34.0909 56.8181V68.1818ZM45.4547 68.1818C45.4547 69.7522 44.184 71.0228 42.6137 71.0228C41.0434 71.0228 39.7727 69.7522 39.7727 68.1818V56.8181C39.7727 55.2478 41.0434 53.9771 42.6137 53.9771C44.184 53.9771 45.4547 55.2478 45.4547 56.8181V68.1818ZM85.2273 68.1818C82.0952 68.1818 79.5456 65.6322 79.5456 62.5001C79.5456 59.368 82.0952 56.8184 85.2273 56.8184C88.3594 56.8184 90.9091 59.368 90.9091 62.5001C90.9091 65.6322 88.3594 68.1818 85.2273 68.1818ZM102.273 68.1818C99.1407 68.1818 96.5911 65.6322 96.5911 62.5001C96.5911 59.368 99.1407 56.8184 102.273 56.8184C105.405 56.8184 107.955 59.368 107.955 62.5001C107.955 65.6322 105.405 68.1818 102.273 68.1818Z"
        fill="#F3F3F3"
      />
      <path
        d="M113.637 85.2273H11.3637C5.09633 85.2273 0 90.3236 0 96.591V113.636C0 119.904 5.09633 125 11.3637 125H113.637C119.904 125 125 119.904 125 113.636V96.591C125 90.3236 119.904 85.2273 113.637 85.2273ZM22.7272 110.795C22.7272 112.366 21.4565 113.636 19.8862 113.636C18.3159 113.636 17.0455 112.366 17.0455 110.795V99.4318C17.0455 97.8615 18.3162 96.5908 19.8865 96.5908C21.4568 96.5908 22.7275 97.8615 22.7275 99.4318V110.795H22.7272ZM34.0909 110.795C34.0909 112.366 32.8202 113.636 31.2499 113.636C29.6796 113.636 28.4089 112.366 28.4089 110.795V99.4318C28.4089 97.8615 29.6796 96.5908 31.2499 96.5908C32.8202 96.5908 34.0909 97.8615 34.0909 99.4318V110.795ZM45.4547 110.795C45.4547 112.366 44.184 113.636 42.6137 113.636C41.0434 113.636 39.7727 112.366 39.7727 110.795V99.4318C39.7727 97.8615 41.0434 96.5908 42.6137 96.5908C44.184 96.5908 45.4547 97.8615 45.4547 99.4318V110.795ZM85.2273 110.795C82.0952 110.795 79.5456 108.246 79.5456 105.114C79.5456 101.982 82.0952 99.432 85.2273 99.432C88.3594 99.432 90.9091 101.982 90.9091 105.114C90.9091 108.246 88.3594 110.795 85.2273 110.795ZM102.273 110.795C99.1407 110.795 96.5911 108.246 96.5911 105.114C96.5911 101.982 99.1407 99.432 102.273 99.432C105.405 99.432 107.955 101.982 107.955 105.114C107.955 108.246 105.405 110.795 102.273 110.795Z"
        fill="#F3F3F3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="125" height="125" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
