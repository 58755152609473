import React from 'react'
import styled from 'styled-components'
import { OnScrollAnimation } from './onScrollAnimation'
import { Work } from '../types/work'

interface Props {
  work: Work
  first: boolean
  last: boolean
}

export const WorkCard = ({ work, first, last }: Props) => {
  const { title, url, urlIsValid, image, desc, tags } = work

  return (
    <Wrapper first={String(first)} last={String(last)}>
      <h3>{title}</h3>
      {urlIsValid ? (
        <a href={url} rel={'noreferrer'} target={'_blank'}>
          {url}
        </a>
      ) : (
        <p>
          <del>{url}</del>
        </p>
      )}
      <OnScrollAnimation animation={'fadeInFromRight'}>
        <S>{image}</S>
      </OnScrollAnimation>
      <Description>{desc}</Description>
      <Tags>
        {tags.map((t: string) => (
          <li key={t}>{t}</li>
        ))}
      </Tags>
      {urlIsValid ? (
        <a href={url} rel={'noreferrer'} target={'_blank'}>
          <Button>Visit</Button>
        </a>
      ) : (
        <Button>
          <del>Visit</del>
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ first: string; last: string }>`
  display: grid;
  grid-template-columns: 5fr 1fr 4fr;
  grid-template-rows: repeat(5, min-content);
  margin-top: ${({ first }) => (first === 'true' ? '70px' : 0)};
  margin-right: auto;
  margin-left: auto;
  margin-bottom: ${({ last }) => (last === 'true' ? '70px' : 0)};
  max-width: ${({ theme }) => theme.content.width};

  h3 {
    ${({ theme }) => theme.typ.workTitle};
    grid-column: 1;
  }

  a,
  p {
    ${({ theme }) => theme.typ.workUrl};
    grid-column: 1;
    width: max-content;
    padding: 5px 0;
    margin-bottom: 13px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr;
  }
`

const S = styled.div`
  grid-column: 3;
  grid-row: 1 / span 6;
  align-self: center;
  height: min-content;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-column: 1;
    grid-row: span 1;
    width: 80%;
    margin: 0 auto 20px auto;
  }
`

const Description = styled.div`
  ${({ theme }) => theme.typ.workDesc};
  grid-column: 1;
`

const Tags = styled.ul`
  grid-column: 1;
  display: flex;

  li {
    ${({ theme }) => theme.typ.workTag};
    display: flex;
    align-items: center;
    padding: 7px 13px 7px 10px;
    margin: 15px 10px 15px 0;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.bg_tag};
  }

  li::before {
    content: ' ';
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 7px;
    border-radius: 50%;
    background-color: white;
  }
`

const Button = styled.div`
  ${({ theme }) => theme.typ.workDetailButton};
  grid-column: 1;
  width: 150px;
  height: 45px;
  margin-top: 33px;
  line-height: 45px;

  text-align: center;
  border-radius: 10px;
  box-shadow: 8px 8px 16px #e7e7e7, -8px -8px 16px #ffffff;

  :active {
    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(212, 212, 212, 0.5),
      inset -7px 7px 14px rgba(212, 212, 212, 0.2), inset 7px -7px 14px rgba(212, 212, 212, 0.2),
      inset -7px -7px 14px rgba(255, 255, 255, 0.9), inset 7px 7px 18px rgba(212, 212, 212, 0.9);
  }
`
