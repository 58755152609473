import React from 'react'
import styled from 'styled-components'

interface Props {
  black?: boolean
  children: React.ReactNode
  zIndex?: string
}

export const SectionFrame = ({ black, children, zIndex }: Props) => {
  return (
    <Section black={String(black)} zIndex={zIndex || ''}>
      {children}
    </Section>
  )
}

const Section = styled.div<any>`
  z-index: ${({ zIndex }) => (zIndex ? 'inherit' : 2)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 60px;
  background-color: ${({ black, theme }) => (black === 'true' ? theme.colors.bg_black : theme.colors.bg_white)};
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 15px;
  }
`
