import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useIsomorphicEffect } from '../hooks/useIsomorphicEffect'

interface Props {
  text: string[]
  initialDelay?: number
  delay?: number
  typingSpeed?: number
  deletingSpeed?: number
  caretSize?: string
  caretColor?: string
  infinite?: boolean
}

export const Typewriter = (props: Props) => {
  const {
    text,
    initialDelay = 0,
    delay = 1000,
    typingSpeed = 200,
    deletingSpeed = 200,
    caretColor = '#000000',
    infinite,
  } = props

  let timer: NodeJS.Timeout
  let currentTextIdx = 0
  let typingIdx = 0

  const [renderingText, setRenderingText] = useState<string>('')

  const isomorphicEffect = useIsomorphicEffect()

  const waitFor = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const typeText = async () => {
    timer = setInterval(() => {
      setRenderingText(text[currentTextIdx].slice(0, typingIdx))
      typingIdx++

      if (typingIdx > text[currentTextIdx].length) {
        clearInterval(timer)
        if (currentTextIdx < text.length - 1) {
          waitFor(delay).then(() => deleteText())
        } else if (currentTextIdx === text.length - 1 && infinite) {
          waitFor(delay).then(() => deleteText())
        }
      }
    }, typingSpeed)
  }

  const deleteText = () => {
    timer = setInterval(() => {
      setRenderingText(text[currentTextIdx].slice(0, typingIdx))
      typingIdx--

      if (typingIdx < 0) {
        clearInterval(timer)
        typingIdx = 0
        currentTextIdx += currentTextIdx < text.length - 1 ? 1 : -currentTextIdx
        typeText()
      }
    }, deletingSpeed)
  }

  isomorphicEffect(() => {
    setTimeout(() => typeText(), initialDelay)

    return () => clearInterval(timer)
  }, [])

  return (
    <>
      {renderingText}
      <Caret color={caretColor}>|</Caret>
    </>
  )
}

const blink_caret = (color: string) => keyframes`
  from, to { color: transparent }
  50% { color: ${color} }
`

const Caret = styled.span<{ color: string }>`
  animation: ${({ color }) => blink_caret(color)} 0.75s step-end infinite;
`
