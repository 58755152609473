import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SectionFrame } from '../sectionFrame'
import { WorkCard } from '../workCard'
import { Work } from '../../types/work'
import { StaticImage } from 'gatsby-plugin-image'

export const WorksSection = () => {
  const { t } = useTranslation()

  const works: Work[] = [
    {
      title: t('works.work1.title'),
      url: 'https://olive-gallery.com',
      urlIsValid: false,
      desc: t('works.work1.desc'),
      tags: ['React', 'Django', 'AWS', 'Stripe'],
      image: <StaticImage src={`../../images/works/work1.png`} alt={'mock'} quality={98} placeholder={'blurred'} />,
    },
    {
      title: t('works.work2.title'),
      url: 'https://simona-n.com',
      urlIsValid: true,
      desc: t('works.work2.desc'),
      tags: ['Admin portal', 'Netlify CMS', 'Gatsby'],
      image: <StaticImage src={`../../images/works/work2.png`} alt={'mock'} quality={98} placeholder={'blurred'} />,
    },
  ]

  return (
    <SectionFrame>
      {works.map((w: Work, i: number) => (
        <React.Fragment key={w.title}>
          <WorkCard work={w} first={i === 0} last={i === works.length - 1} />
          {i === works.length - 1 ? null : <Border />}
        </React.Fragment>
      ))}
    </SectionFrame>
  )
}

const Border = styled.div`
  height: 16px;
  margin: 100px 0;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(234, 234, 234, 0.5),
    inset -2px 2px 4px rgba(234, 234, 234, 0.2), inset 2px -2px 4px rgba(234, 234, 234, 0.2),
    inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(234, 234, 234, 0.9);
  border-radius: 20px;
`
