import React from 'react'
import styled from 'styled-components'
import { SectionFrame } from '../sectionFrame'
import { PaperPlaneIcon } from '../../images/paperPlaneIcon'
import { OnScrollAnimation } from '../onScrollAnimation'
import { useTranslation } from 'react-i18next'

export const ContactSection = () => {
  const { t } = useTranslation()
  return (
    <SectionFrame>
      <Wrapper>
        <Contact>
          <p>Contact</p>
          <span>{t('contact.message')}</span>
        </Contact>
        <Form>
          <input placeholder={'name'} />
          <input placeholder={'email'} />
          <input placeholder={'subject'} />
          <textarea placeholder={'message'} />
          <OnScrollAnimation animation={'bounceIn'}>
            <button>
              <PaperPlane fill={'white'} />
            </button>
          </OnScrollAnimation>
        </Form>
      </Wrapper>
      <Footer>©︎2021 tk.dev</Footer>
    </SectionFrame>
  )
}

const Wrapper = styled.div`
  width: min(80vw, 800px);
  margin: 0 auto;
`

const Contact = styled.div`
  padding: 40px 0 100px 0;
  text-align: center;

  p {
    ${({ theme }) => theme.typ.contactTitle};
    padding-bottom: 10px;
  }

  span {
    ${({ theme }) => theme.typ.contactDesc};
  }
`

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 46px;

  input {
    ${({ theme }) => theme.typ.contactInput};

    width: 100%;
    height: 42px;
    padding-left: 18px;

    -webkit-appearance: none;
    border: none;
    border-radius: 10px;
    box-shadow: inset -2px -2px 2px rgba(0, 0, 0, 0.03), inset 2px 2px 2px -1px rgba(0, 0, 0, 0.07);
    background-color: ${({ theme }) => theme.colors.bg_input};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      grid-column: span 2;
    }
  }

  input::placeholder {
    ${({ theme }) => theme.typ.contactPlaceholder};
  }

  input:nth-child(3) {
    grid-column: span 2;
  }

  textarea {
    ${({ theme }) => theme.typ.contactInput};

    grid-column: span 2;
    height: 218px;
    padding: 18px;

    -webkit-appearance: none;
    border: none;
    border-radius: 10px;
    box-shadow: inset -2px -2px 2px rgba(0, 0, 0, 0.03), inset 2px 2px 2px -1px rgba(0, 0, 0, 0.07);
    background-color: ${({ theme }) => theme.colors.bg_input};

    resize: none;
  }

  textarea::placeholder {
    ${({ theme }) => theme.typ.contactPlaceholder};
  }

  button {
    ${({ theme }) => theme.typ.contactButton};
    grid-column: span 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    border: none;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.bg_black};
    cursor: pointer;

    //svg {
    //  height: 32px;
    //  margin-left: 10px;
    //  transform: rotate(25deg) translateY(1px) translateX(-8px);
    //}
  }
`

const PaperPlane = styled(PaperPlaneIcon)`
  height: 32px;
  margin-left: 10px;
  transform: rotate(25deg) translateY(1px) translateX(-8px);
`

const Footer = styled.footer`
  ${({ theme }) => theme.typ.footer};

  width: 100%;
  max-width: ${({ theme }) => theme.content.width};
  margin: 100px auto 0 auto;
  padding-top: 20px;
  border-top: 1px solid grey;
  text-align: center;
`
