import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { SectionFrame } from '../sectionFrame'
import { OnScrollAnimation } from '../onScrollAnimation'
import { useTranslation } from 'react-i18next'

export const ProfileSection = () => {
  const { t } = useTranslation()

  const history = [
    {
      date: '2021 - current',
      title: 'Freelance web engineer 🇩🇪 🇯🇵',
    },
    {
      date: '2020 - 2021',
      title: 'Software engineer [Frontend] 🇩🇪',
    },
    {
      date: '2019 - 2020',
      title: 'Co-founder/Developer [Fullstack] 🇯🇵',
    },
    {
      date: '2018 - 2019',
      title: 'Frontend engineer 🇯🇵',
    },
    {
      date: '2014 - current',
      title: 'Photographer 🇯🇵',
    },
  ]

  return (
    <SectionFrame black={true}>
      <Grid>
        <Avatar>
          <OnScrollAnimation animation={'rotateIn'}>
            <_Img>
              <StaticImage
                src={'../../images/profile/me.jpg'}
                width={150}
                quality={100}
                alt={'me'}
                placeholder={'blurred'}
              />
            </_Img>
          </OnScrollAnimation>
          <p>Toshiki K.</p>
        </Avatar>
        <Info>
          <h3>Web engineer / Photographer</h3>
          <div>
            <p>{t('profile.language')}</p>
            <p>{t('profile.interests')}</p>
          </div>
          <p>{t('profile.message')}</p>
          <ul>
            <a href={'https://github.com/Zett-8'} rel={'noreferrer'} target={'_blank'}>
              <li>
                <StaticImage src={'../../images/profile/github.png'} alt={'logo'} width={18} quality={100} />
              </li>
            </a>
            <a href={'https://www.facebook.com/toshiki.kamei.3/'} rel={'noreferrer'} target={'_blank'}>
              <li>
                <StaticImage src={'../../images/profile/facebook.png'} alt={'logo'} width={18} quality={100} />
              </li>
            </a>
            <a href={'https://toshikikamei.com'} rel={'noreferrer'} target={'_blank'}>
              <li>
                <StaticImage src={'../../images/profile/photo.png'} alt={'logo'} width={18} quality={100} />
              </li>
            </a>
          </ul>
        </Info>
        <Line>
          <div />
        </Line>
        <History>
          {history.map((h) => (
            <div key={h.title}>
              <span>{h.date}</span>
              <p>{h.title}</p>
            </div>
          ))}
        </History>
      </Grid>
    </SectionFrame>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 33px;
  grid-row-gap: 40px;
  max-width: 600px;
  margin: 0 auto;
  padding: 100px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 100px 1fr;
    grid-column-gap: 23px;
  }
`

const Avatar = styled.div`
  ${({ theme }) => theme.typ.profileMyName};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  p {
    padding: 20px 0;
  }
`

const _Img = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  * {
    border-radius: 50%;
  }

  box-shadow: 2px 2px 17px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100px;
    height: 100px;
  }
`

const Info = styled.div`
  > * {
    margin-bottom: 15px;
  }

  h3 {
    ${({ theme }) => theme.typ.profileTitle};
  }

  > div > p {
    ${({ theme }) => theme.typ.profileSubDesc};
    padding: 2px 0;
  }

  > p {
    ${({ theme }) => theme.typ.profileDesc};
    line-height: 2rem;
  }

  ul {
    display: flex;
    margin-top: 40px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 36px;
      margin-right: 35px;
      text-align: center;
      line-height: 30px;

      border-radius: 10px;
      background: #1b1b1b;
      box-shadow: 10px 10px 18px #101010, -10px -10px 18px #262626;

      > div {
        width: 18px;
      }
    }

    li:active {
      border-radius: 10px;
      background: #1b1b1b;
      box-shadow: inset 12px 12px 20px #171717, inset -12px -12px 20px #1f1f1f;
    }
  }
`

const Line = styled.div`
  div {
    width: 2px;
    height: calc(100% + 70px);
    margin: -30px auto 0 auto;
    background-color: white;
    border-radius: 2px;
  }
`

const History = styled.div`
  margin-left: -74px;

  div {
    margin: 40px 0;
  }

  span {
    ${({ theme }) => theme.typ.resumeDate};
  }

  p {
    ${({ theme }) => theme.typ.resumeTitle};
    padding: 3px 0;
  }

  p::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 29px 0 -40px;
    border-radius: 50%;
    background-color: white;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-left: -39px;
  }
`
