import React from 'react'
import styled from 'styled-components'

export const AnimatedWave = () => {
  return (
    <Wave>
      <svg viewBox="0 0 120 28">
        <defs>
          <path
            id="wave"
            d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
          />
        </defs>

        <use id="wave3" className="wave" xlinkHref="#wave" x="0" y="-2" />
        <use id="wave2" className="wave" xlinkHref="#wave" x="0" y="0" />
        <use id="wave1" className="wave" xlinkHref="#wave" x="0" y="1" />
      </svg>
    </Wave>
  )
}

const Wave = styled.div`
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  pointer-events: none;

  svg {
    width: 100%;
    overflow: visible;
  }

  .wave {
    animation: wave 4s linear;
    animation-iteration-count: infinite;
    fill: ${({ theme }) => theme.colors.bg_black};
  }

  #wave2 {
    animation-duration: 6s;
    animation-direction: reverse;
    opacity: 0.6;
  }
  #wave3 {
    animation-duration: 8s;
    opacity: 0.3;
  }

  @keyframes wave {
    to {
      transform: translateX(-100%);
    }
  }
`
