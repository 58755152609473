import React from 'react'
import styled from 'styled-components'
import { SectionFrame } from '../sectionFrame'
import { Typewriter } from '../typewriter'
import { theme } from '../../styles/theme'
import { AnimatedWave } from '../waveAnimation'

export const LandingSection = () => {
  return (
    <SectionFrame zIndex={'0'}>
      <H1>
        <Typewriter text={['tk.dev', 'toshikikamei.dev']} caretColor={theme().colors.typ_black} />
      </H1>
      <AnimatedWave />
    </SectionFrame>
  )
}

const H1 = styled.h1`
  ${({ theme }) => theme.typ.logo};
  position: fixed;
  margin: auto;
  align-self: center;
  letter-spacing: 1rem;
`
