import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { EarthIcon } from '../images/earthIcon'

export const LanguageSwitch = () => {
  const { i18n } = useTranslation()

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'ja' : 'en')
  }

  return (
    <Wrapper onClick={toggleLanguage}>
      <EarthIcon />
      {i18n.language === 'en' ? (
        <p>
          <span>EN</span> — JA
        </p>
      ) : (
        <p>
          EN — <span>JA</span>
        </p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ theme }) => theme.typ.langSwitch};
  z-index: 1;
  position: fixed;
  top: 60px;
  right: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 123px;
  height: 41px;

  cursor: pointer;

  box-shadow: -5px 5px 10px rgba(227, 227, 227, 0.2), 5px -5px 10px rgba(227, 227, 227, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(227, 227, 227, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(227, 227, 227, 0.5);
  border-radius: 10px;

  :active {
    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(227, 227, 227, 0.5),
      inset -5px 5px 10px rgba(227, 227, 227, 0.2), inset 5px -5px 10px rgba(227, 227, 227, 0.2),
      inset -5px -5px 10px rgba(255, 255, 255, 0.9), inset 5px 5px 13px rgba(227, 227, 227, 0.9);
  }

  svg {
    width: 20px;
  }

  span {
    color: ${({ theme }) => theme.colors.typ_black};
    font-weight: 500;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    top: 20px;
    right: 20px;
  }
`
