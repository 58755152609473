import React from 'react'

export const DesignIcon = (props: any) => (
  <svg {...props} viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M138.246 2.82245H2.7539C1.23292 2.82245 0 4.05564 0 5.57635V33.9026C0 35.4233 1.23292 36.6565 2.7539 36.6565H138.246C139.767 36.6565 141 35.4233 141 33.9026V5.57635C141 4.05564 139.767 2.82245 138.246 2.82245ZM135.492 31.1487H5.50779V8.33024H135.492V31.1487Z"
      fill="#F3F3F3"
    />
    <path
      d="M129.271 24.8936L127.021 22.6445C127.635 21.4305 127.962 20.0817 127.962 18.6822C127.962 16.3323 127.047 14.1225 125.385 12.4608C121.955 9.03085 116.374 9.03058 112.943 12.4608C111.282 14.1225 110.366 16.3323 110.366 18.6822C110.366 21.0321 111.282 23.2418 112.943 24.9032C114.605 26.5649 116.815 27.48 119.165 27.48C120.564 27.48 121.913 27.1529 123.127 26.5393L125.376 28.7884C125.914 29.326 126.619 29.595 127.323 29.595C128.028 29.595 128.733 29.326 129.271 28.7884C130.346 27.713 130.346 25.9692 129.271 24.8936ZM121.491 21.0084C120.87 21.6299 120.044 21.9722 119.165 21.9722C118.286 21.9722 117.46 21.6299 116.838 21.0084C116.217 20.3868 115.874 19.5609 115.874 18.6822C115.874 17.8034 116.217 16.9769 116.838 16.3557C117.479 15.7143 118.322 15.3937 119.165 15.3937C120.007 15.3937 120.85 15.7143 121.491 16.3557C122.112 16.9772 122.455 17.8034 122.455 18.6822C122.455 19.5609 122.113 20.3871 121.491 21.0084Z"
      fill="#F3F3F3"
    />
    <path
      d="M98.6833 24.1877C98.6475 24.0112 98.5952 23.8377 98.5263 23.6728C98.4575 23.5072 98.3721 23.345 98.273 23.1963C98.1738 23.0476 98.0582 22.9044 97.9315 22.7777C97.8048 22.6511 97.6644 22.5354 97.5129 22.4363C97.3642 22.3371 97.2045 22.2517 97.0365 22.1829C96.8712 22.1141 96.6978 22.0617 96.5215 22.0259C96.1662 21.9543 95.8 21.9543 95.4447 22.0259C95.2685 22.0617 95.095 22.1141 94.9297 22.1829C94.7645 22.2517 94.6048 22.3371 94.4561 22.4363C94.3046 22.5354 94.1642 22.6511 94.0347 22.7777C93.9081 22.9044 93.7951 23.0473 93.6933 23.1963C93.5941 23.345 93.5087 23.5075 93.4399 23.6728C93.371 23.838 93.3187 24.0112 93.2829 24.1877C93.2471 24.3665 93.2306 24.5457 93.2306 24.7275C93.2306 24.9062 93.2471 25.0855 93.2829 25.2645C93.3187 25.4408 93.371 25.6143 93.4399 25.7792C93.5087 25.9447 93.5941 26.1044 93.6933 26.2556C93.7951 26.4043 93.9081 26.5476 94.0347 26.6742C94.1642 26.8009 94.3046 26.9168 94.4561 27.0157C94.6048 27.1149 94.7645 27.2002 94.9297 27.2691C95.0977 27.3379 95.2712 27.3902 95.4447 27.426C95.6237 27.4618 95.8055 27.4784 95.9845 27.4784C96.1635 27.4784 96.3453 27.4616 96.5215 27.426C96.6978 27.3905 96.8712 27.3379 97.0365 27.2691C97.2017 27.2002 97.3642 27.1149 97.5129 27.0157C97.6644 26.9168 97.8048 26.8009 97.9315 26.6742C98.4437 26.1595 98.7384 25.4518 98.7384 24.7275C98.7384 24.5457 98.7191 24.3665 98.6833 24.1877Z"
      fill="#F3F3F3"
    />
    <path
      d="M85.5434 21.9722H28.3083C26.7874 21.9722 25.5544 23.2054 25.5544 24.7261C25.5544 26.2468 26.7874 27.48 28.3083 27.48H85.5434C87.0644 27.48 88.2973 26.2468 88.2973 24.7261C88.2973 23.2054 87.0644 21.9722 85.5434 21.9722Z"
      fill="#F3F3F3"
    />
    <path
      d="M86.6984 40.5825H3.07909C1.55811 40.5825 0.325195 41.8157 0.325195 43.3364V97.6429C0.325195 99.1639 1.55811 100.397 3.07909 100.397H86.6984C88.2194 100.397 89.4523 99.1636 89.4523 97.6429V43.3364C89.4523 41.8157 88.2194 40.5825 86.6984 40.5825ZM33.5441 94.8888H10.1687L25.9114 76.6618L37.3125 89.864L33.5441 94.8888ZM40.4288 94.8888L45.5637 88.0423H58.9171C60.4381 88.0423 61.671 86.8091 61.671 85.2884C61.671 83.7677 60.4381 82.5345 58.9171 82.5345H49.6948L51.627 79.9585H68.9184L80.1171 94.8888H40.4288ZM55.7575 74.4507L60.2725 68.431L64.7876 74.4507H55.7575ZM83.9445 90.8125L62.4757 62.1885C61.9555 61.495 61.1392 61.0869 60.2725 61.0869C59.4059 61.0869 58.5896 61.495 58.0694 62.1885L40.6951 85.354L27.9958 70.6482C27.4728 70.0426 26.7119 69.6942 25.9116 69.6942C25.1114 69.6942 24.3507 70.0426 23.8275 70.6482L5.83299 91.4822V46.0903H83.9445V90.8125Z"
      fill="#F3F3F3"
    />
    <path
      d="M37.9069 49.2523C32.5384 49.2523 28.171 53.6197 28.171 58.9882C28.171 64.3566 32.5387 68.724 37.9069 68.724C43.2753 68.724 47.6427 64.3566 47.6427 58.9882C47.6427 53.6197 43.2753 49.2523 37.9069 49.2523ZM37.9069 63.2165C35.5754 63.2165 33.6788 61.3199 33.6788 58.9884C33.6788 56.657 35.5757 54.7604 37.9069 54.7604C40.238 54.7604 42.1349 56.657 42.1349 58.9884C42.1349 61.3199 40.2383 63.2165 37.9069 63.2165Z"
      fill="#F3F3F3"
    />
    <path
      d="M138.246 40.5825H95.9215C94.4005 40.5825 93.1676 41.8157 93.1676 43.3364V97.6429C93.1676 99.1636 94.4005 100.397 95.9215 100.397H138.246C139.767 100.397 141 99.1636 141 97.6429V43.3364C141 41.8157 139.767 40.5825 138.246 40.5825ZM135.492 94.889H98.6754V46.0903H135.492V94.889Z"
      fill="#F3F3F3"
    />
    <path
      d="M129.08 50.6262H105.087C103.566 50.6262 102.333 51.8594 102.333 53.3801C102.333 54.9008 103.566 56.134 105.087 56.134H129.08C130.601 56.134 131.834 54.9008 131.834 53.3801C131.834 51.8594 130.601 50.6262 129.08 50.6262Z"
      fill="#F3F3F3"
    />
    <path
      d="M129.08 61.6787H105.087C103.566 61.6787 102.333 62.9119 102.333 64.4326C102.333 65.9533 103.566 67.1865 105.087 67.1865H129.08C130.601 67.1865 131.834 65.9533 131.834 64.4326C131.834 62.9119 130.601 61.6787 129.08 61.6787Z"
      fill="#F3F3F3"
    />
    <path
      d="M129.08 72.7312H105.087C103.566 72.7312 102.333 73.9644 102.333 75.4851C102.333 77.0058 103.566 78.239 105.087 78.239H129.08C130.601 78.239 131.834 77.0058 131.834 75.4851C131.834 73.9644 130.601 72.7312 129.08 72.7312Z"
      fill="#F3F3F3"
    />
    <path
      d="M107.788 85.9989C107.752 85.8224 107.7 85.6489 107.631 85.484C107.562 85.3184 107.477 85.1587 107.377 85.0075C107.276 84.8588 107.163 84.7156 107.033 84.5889C106.394 83.95 105.444 83.6551 104.549 83.8371C104.373 83.8729 104.199 83.9253 104.034 83.9941C103.869 84.0629 103.709 84.1483 103.558 84.2475C103.409 84.3466 103.266 84.4623 103.139 84.5889C103.012 84.7156 102.897 84.8585 102.798 85.0075C102.699 85.159 102.613 85.3187 102.544 85.484C102.475 85.6492 102.423 85.8224 102.387 85.9989C102.352 86.1777 102.332 86.3569 102.332 86.5357C102.332 86.7174 102.352 86.8967 102.387 87.0754C102.423 87.252 102.475 87.4255 102.544 87.5907C102.613 87.7562 102.699 87.9159 102.798 88.0671C102.897 88.2158 103.012 88.359 103.139 88.4857C103.266 88.6124 103.409 88.728 103.558 88.8272C103.709 88.9263 103.869 89.0117 104.034 89.0805C104.199 89.1494 104.373 89.2017 104.549 89.2375C104.728 89.2733 104.907 89.2926 105.089 89.2926C105.813 89.2926 106.521 88.9979 107.033 88.4854C107.16 88.3587 107.276 88.2158 107.377 88.0668C107.477 87.9154 107.562 87.7556 107.631 87.5904C107.7 87.4252 107.752 87.252 107.788 87.0754C107.824 86.8967 107.84 86.7174 107.84 86.5357C107.84 86.3569 107.824 86.1777 107.788 85.9989Z"
      fill="#F3F3F3"
    />
    <path
      d="M129.08 83.7834H115.527C114.006 83.7834 112.773 85.0166 112.773 86.5373C112.773 88.058 114.006 89.2912 115.527 89.2912H129.08C130.601 89.2912 131.834 88.058 131.834 86.5373C131.834 85.0166 130.601 83.7834 129.08 83.7834Z"
      fill="#F3F3F3"
    />
    <path
      d="M45.4037 104.304H3.07909C1.55811 104.304 0.325195 105.537 0.325195 107.058V135.424C0.325195 136.944 1.55811 138.178 3.07909 138.178H45.4037C46.9247 138.178 48.1576 136.944 48.1576 135.424V107.058C48.1576 105.537 46.9247 104.304 45.4037 104.304ZM42.6498 132.67H5.83299V109.812H42.6498V132.67Z"
      fill="#F3F3F3"
    />
    <path
      d="M138.246 104.304H55.0029C53.4819 104.304 52.249 105.537 52.249 107.058V135.424C52.249 136.944 53.4819 138.178 55.0029 138.178H138.246C139.767 138.178 141 136.944 141 135.424V107.058C141 105.537 139.767 104.304 138.246 104.304ZM135.492 132.67H57.7568V109.812H135.492V132.67Z"
      fill="#F3F3F3"
    />
    <path
      d="M70.3017 120.704C70.2659 120.528 70.2136 120.354 70.1447 120.189C70.0759 120.021 69.9905 119.861 69.8914 119.713C69.7922 119.561 69.6766 119.42 69.5499 119.294C69.4232 119.167 69.2828 119.051 69.1313 118.952C68.9826 118.853 68.8229 118.768 68.6549 118.699C68.4897 118.63 68.3162 118.575 68.1399 118.539C67.7847 118.47 67.4184 118.47 67.0631 118.539C66.8896 118.575 66.7161 118.63 66.5482 118.699C66.3829 118.768 66.2232 118.853 66.0745 118.952C65.923 119.051 65.7826 119.167 65.6559 119.294C65.5292 119.42 65.4135 119.561 65.3117 119.713C65.2125 119.861 65.1271 120.021 65.0583 120.189C64.9894 120.354 64.9371 120.528 64.9013 120.704C64.8655 120.88 64.849 121.062 64.849 121.241C64.849 121.419 64.8655 121.602 64.9013 121.778C64.9371 121.954 64.9894 122.127 65.0583 122.295C65.1271 122.46 65.2125 122.62 65.3117 122.769C65.4135 122.92 65.5292 123.061 65.6559 123.187C66.1681 123.7 66.8786 123.995 67.6029 123.995C67.7819 123.995 67.9637 123.978 68.1399 123.942C68.3162 123.907 68.4897 123.851 68.6549 123.783C68.8229 123.714 68.9826 123.631 69.1313 123.529C69.2828 123.43 69.4232 123.315 69.5499 123.188C69.6766 123.061 69.7922 122.921 69.8914 122.769C69.9905 122.62 70.0759 122.461 70.1447 122.296C70.2136 122.128 70.2659 121.954 70.3017 121.778C70.3375 121.601 70.3568 121.419 70.3568 121.241C70.3568 121.062 70.3375 120.88 70.3017 120.704Z"
      fill="#F3F3F3"
    />
    <path
      d="M124.289 118.487H78.0423C76.5214 118.487 75.2885 119.72 75.2885 121.241C75.2885 122.762 76.5214 123.995 78.0423 123.995H124.289C125.809 123.995 127.042 122.762 127.042 121.241C127.042 119.72 125.809 118.487 124.289 118.487Z"
      fill="#F3F3F3"
    />
  </svg>
)
