import React from 'react'

import SEO from '../components/seo'
import { LanguageSwitch } from '../components/languageSwitch'
import { LandingSection } from '../components/sections/LandingSection'
import { TechStackSection } from '../components/sections/techStackSection'
import { WorksSection } from '../components/sections/worksSection'
import { ProfileSection } from '../components/sections/profileSection'
import { ContactSection } from '../components/sections/contactSection'

const IndexPage = () => {
  return (
    <>
      <SEO />
      <LanguageSwitch />
      <LandingSection />
      <TechStackSection />
      <WorksSection />
      <ProfileSection />
      <ContactSection />
    </>
  )
}

export default IndexPage
